import React from "react";

import { graphql } from "gatsby";
import Page from "../Layout/Page";
import getGatsbyImage from "../utils/getGatsbyImage";
import { getSrc } from "gatsby-plugin-image";
import { Box, Stack, Typography } from "@mui/material";
import { Artwork, QueryResult } from "../utils/types";
import { sizes } from "../utils/constants";
import { Helmet } from "react-helmet";
import { getSeoLinks, getSeoMetas } from "../components/SeoHelper";

export const query = graphql`
  {
    lisa: allImageSharp(filter: { original: { src: { regex: "/lisa/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
  }
`;

function getArtworksFromData(data: { lisa: QueryResult }): Artwork[] {
  return [
    {
      src: getSrc(getGatsbyImage(data.lisa)) || "",
      title: "Lisa Maria Angrino",
    },
  ];
}

const About: React.VFC = ({ data }: any) => {
  const artworks = getArtworksFromData(data);
  return (
    <Page title={"about"}>
      <Helmet
        title="about"
        titleTemplate="%s · Lisa Angrino"
        meta={getSeoMetas({
          title: "about",
          description:
            "Learn more about Lisa Angrino, a visual artist and philosopher from Colombia, and how to contact her",
          path: "about",
        })}
        link={getSeoLinks({ path: "about" })}
      />
      <Box
        flex="1"
        justifyContent="center"
        display="flex"
        flexDirection="row"
        paddingTop={sizes.l}
      >
        <Box maxWidth={"600px"}>
          <Stack spacing={2}>
            <Box height={"200px"} display={"flex"} justifyContent="center">
              <img
                alt={artworks[0].title}
                src={artworks[0].src}
                style={{
                  width: "200px",
                  maxHeight: "200px",
                  borderRadius: "100px",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box display={"flex"} justifyContent="center">
              <Box
                height="10px"
                width="10px"
                bgcolor={"black"}
                borderRadius="100px"
              />
            </Box>
            <Typography>
              Lisa Angrino (b. 1995) is a visual artist and philosopher from
              Colombia. While she experiments with materials and media, her
              thematic focus has always been the interpretation, translation and
              transmission of dreams, feelings, and the connection between the
              real and the unreal.
            </Typography>
            <Typography>
              Her work is deeply sensory, trying to communicate with the visual,
              elements of sound, texture and feeling, with a signature that is
              always dynamic and organic. Recently, her paintings have concerned
              themselves with death, and its portrayal as a gentle, mystical
              force, a quiet shadow to the absurdity of life.
            </Typography>
            <Typography>
              She is compelled to make connections with the observers of her
              art, believing that without the gaze of the observer, no art can
              truly have taken place. Her latest work is greatly influenced by
              existentialist philosophies and focuses on human love
              relationships and their imminent connection to the death drive.
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Page>
  );
};

export default About;
